import { Burger, Group, Text } from "@mantine/core";

export function ButtonBurguer({ opened, onClick, text = "", ...props }) {
	return (
		<Group onClick={onClick} style={{ cursor: "pointer" }} gap="xxs" {...props}>
			<Burger opened={opened} size="sm" />
			{text && <Text>{text}</Text>}
		</Group>
	);
}
