import { userStore } from "@/mobx/rootStore";
import { routesList } from "@/routesList";
import { logout } from "@/utils/auth/logout";
import { Button, Group, Stack, Text } from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { IconLogout, IconUserCircle } from "@tabler/icons-react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { uiStore } from "@/mobx/rootStore";

export default observer(function UserInfo() {
	const navigate = useNavigate();
	
	function handleNavigate(path) {
		uiStore.closeMobileNavbar();
		navigate(routesList.settings.path);
	}

	return (
		<Stack gap="sm">
			<Group ml="xs" gap="xs">
				<IconUserCircle />
				<Text size="lg">
					<Text span c="dimmed">
						Usuario:&nbsp;
					</Text>
					{userStore?.data?.username}
				</Text>
			</Group>

			<Button
				variant="subtle"
				leftSection={<IconSettings />}
				justify="flex-start"
				onClick={() => handleNavigate(routesList.settings.path)}
			>
				<Text>Ajustes</Text>
			</Button>

			<Button
				variant="subtle"
				color="red"
				leftSection={<IconLogout />}
				justify="flex-start"
				onClick={() => {
					logout({});
				}}
			>
				<Text>Salir</Text>
			</Button>
		</Stack>
	);
});
