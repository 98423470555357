import { makeAutoObservable } from "mobx";
import construct from "../utils/construct";
import persist from "../utils/persist";

const defaultProperties = {
	language: "es-ES",
	theme: "dark",
	header: {
		height: 70,
	},
	navbar: {
		width: 0,
		mobileOpened: false,
		desktopOpened: true,
	},
	thumbBurger: {
		show: true,
		position: "left",
	},
};

export default class UiStore {
	constructor() {
		construct(this, defaultProperties);

		makeAutoObservable(this);
		persist(this, "uiStore");
	}

	reset() {
		construct(this, defaultProperties);
	}

	/** * @param {"light" | "dark"} theme */
	toggleTheme = (theme) => {
		// Forzamos tema
		if (theme) {
			if (!["light", "dark"].includes(theme)) {
				return console.warn(`[setTheme] ${theme} is not a valid theme`);
			}

			this.theme = theme;

			// Cambiamos de un tema al otro
		} else {
			if (this.theme === "light") {
				this.theme = "dark";
			} else {
				this.theme = "light";
			}
		}
	};

	/**
	 * Devuelve si estamos en tema oscuro o no.
	 * @returns {boolean} true si estamos en tema oscuro, false si no.
	 */
	isDarkTheme = () => this.theme === "dark";

	getMobileNavbarState = () => this.navbar.mobileOpened;

	toggleMobileNavbar = () => {
		const nextState = !this.navbar.mobileOpened;
		this.navbar.mobileOpened = nextState;
		this.navbar.desktopOpened = nextState;
	};
	closeMobileNavbar = () => {
		this.navbar.mobileOpened = false;
		this.navbar.desktopOpened = false;
	};
	openMobileNavbar = () => {
		this.navbar.mobileOpened = true;
		this.navbar.desktopOpened = true;
	};

	getDesktopNavbarState = () => this.navbar.desktopOpened;

	toggleDesktopNavbar = () => {
		this.navbar.desktopOpened = !this.navbar.desktopOpened;
	};

	closeNavbar = () => {
		this.navbar.desktopOpened = false;
		this.navbar.mobileOpened = false;
	};
	openNavbar = () => {
		this.navbar.desktopOpened = true;
		this.navbar.mobileOpened = true;
	};
	toggleNavbar = () => {
		const nextState = !this.navbar.desktopOpened;
		this.navbar.desktopOpened = nextState;
		this.navbar.mobileOpened = nextState;
	};

	/**
	 * @param {"left" | "right"} position
	 */
	setThumbBurgerPosition = (position) => {
		this.thumbBurger.position = position;
	};

	toggleThumbBurgerPosition = () => {
		if (this.thumbBurger.position === "left") {
			this.setThumbBurgerPosition("right");
		} else {
			this.setThumbBurgerPosition("left");
		}
	};
}
