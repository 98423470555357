import { logout } from "@/utils/auth/logout";
import { fetchMe } from "@/utils/auth/me";
import { endpoints } from "@/utils/common/requests/endpoints";
import Bugsnag from "@bugsnag/js";
import dame from "dame";
import { makeAutoObservable } from "mobx";
import construct from "../utils/construct";
import persist from "../utils/persist";

const { VITE_ENV } = import.meta.env;

// eslint-disable-next-line
let _rootStore;

/**
 * @typedef {Object} UserStoreProps
 * @property {string} token The authentication token for the user
 * @property {{}} data The user data fetched from the server
 * @property {"mementos" | "items" | null} lastApp The last application the user interacted with
 * @property {{
 * 	opt_defaultApp: "mementos" | "items" | "economos",
 *  opt_thumbBurger: "hidden" | "left" | "right"
 * }} settings
 */

/** @type {UserStoreProps} */
const defaultProperties = {
	token: null,
	data: null,
	lastApp: null,
	settings: {
		opt_defaultApp: null,
		opt_thumbBurger: null,
	},
};

export default class UserStore {
	token = defaultProperties.token;
	data = defaultProperties.data;
	lastApp = defaultProperties.lastApp;
	settings = defaultProperties.settings;

	constructor(rootStore) {
		construct(this, defaultProperties);
		_rootStore = rootStore;

		makeAutoObservable(this);
		persist(this, "userStore");
	}

	/**
	 * @param {keyof typeof defaultProperties["settings"]} key
	 */
	getSetting(key) {
		return this.settings[key];
	}

	/**
	 * @param {keyof typeof defaultProperties["settings"]} key
	 * @returns
	 */
	setSetting(key, value) {
		this.settings[key] = value;
	}

	reset() {
		construct(this, defaultProperties);
	}

	async fetchMe() {
		const { success, me } = await fetchMe();

		if (!success) {
			if (VITE_ENV === "pro") {
				return logout();
			}
		}

		if (!success) {
			return console.log("[UserStore.fetchData] error");
		}

		if (VITE_ENV === "pro") {
			Bugsnag.setUser(me?.id, me?.email, me?.username);
		}

		this.data = me;
	}

	async fetchSettings() {
		const { response, isError } = await dame.get(endpoints.settings.GET());

		if (isError) {
			return console.log("[UserStore.fetchSettings] error");
		}

		this.settings = response;
	}

	async init() {
		console.log("[agora] INIT: Iniciando...");

		if (this.token) {
			this.fetchMe();
			this.fetchSettings();
			// staticDataStore.fetchData();

			console.log("[agora] INIT: ok");
		} else {
			console.log("[agora] INIT: no token");
			logout();
		}
	}
}
