import { uiStore } from "@/mobx/rootStore";
import { ActionIcon } from "@mantine/core";
import { useRef } from "react";
import { ButtonBurguer } from "./ButtonBurguer";

export const ButtonBurguerThumb = ({ opened, onClick, text = "" }) => {
	const timerRef = useRef(null);
	const ignoreUpClick = useRef(false);

	function handleDown() {
		timerRef.current = setTimeout(() => {
			uiStore.toggleThumbBurgerPosition();
			ignoreUpClick.current = true;

			timerRef.current = null;
		}, 500);
	}

	function handleUp() {
		if (ignoreUpClick.current) {
			ignoreUpClick.current = false;
			return;
		}

		if (timerRef.current) {
			clearTimeout(timerRef.current);
			timerRef.current = null;
		}

		onClick();
	}

	return (
		<ActionIcon variant="light" color="gray" component="div">
			<ButtonBurguer
				opened={opened}
				onMouseDown={handleDown}
				onTouchStart={handleDown}
				onMouseUp={handleUp}
				onTouchEnd={handleUp}
				text={text}
			/>
		</ActionIcon>
	);
};
