import { useMatches } from "@mantine/core";

export function useAppSizeId() {
	const appSizeId = useMatches({
		base: 0,
		xs: 0,
		sm: 1,
		md: 2,
		lg: 3,
		xl: 4,
	});

	return appSizeId;
}
